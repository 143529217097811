import {
  BuyOderRequestDTO,
  BuyRecDTO,
  IRenewableOptionsTotal,
  IRenewableParameters,
  IRenewableToBuyParameters,
} from "@/models/RenewableOptions";
import { IResponse } from "@/services/axios/entities";
import { catchAxiosError } from "@/services/axios/error";
import HttpClient from "@/services/axios/instance";
import { getEnv } from "@/utils/storage";

const RENEWABLE_OPTIONS = "RenewableOptions";
const env = getEnv();
export class RenewableOptionAPI extends HttpClient {
  constructor(token?: string, accountId?: string) {
    super(env?.VUE_APP_DOMAIN_API_SERVER, token, accountId);
  }
  public getRenewableOptions = async (
    params?: IRenewableParameters
  ): Promise<IResponse> => {
    const config = {
      params: params,
      headers: {
        "Accept-Account": params?.AccountId || "",
      },
    };
    const response: IResponse = await this.instance
      .get(RENEWABLE_OPTIONS, config)
      .catch(catchAxiosError);

    return response;
  };

  public getBuyList = async (
    params: IRenewableToBuyParameters
  ): Promise<IResponse> => {
    const config = {
      params: params,
      headers: {
        "Accept-Account": params.AccountId || "",
      },
    };
    const response: IResponse = await this.instance
      .get(`${RENEWABLE_OPTIONS}/Marketplace`, config)
      .catch(catchAxiosError);

    return response;
  };
  public addCartItem = async (data: BuyOderRequestDTO): Promise<IResponse> => {
    const config = {
      headers: {
        "Accept-Account": data.AccountId || "",
      },
    };
    const response: IResponse = await this.instance
      .post(`${RENEWABLE_OPTIONS}/AddCart`, data, config)
      .catch(catchAxiosError);

    return response;
  };

  public getCartItem = async (accountId: string): Promise<IResponse> => {
    const config = {
      headers: {
        "Accept-Account": accountId || "",
      },
    };
    const response: IResponse = await this.instance
      .get(`${RENEWABLE_OPTIONS}/CartItems`, config)
      .catch(catchAxiosError);

    return response;
  };
  public removeCartItem = async (payload: {
    accountId: string;
    buyOrderItemId: string;
  }): Promise<IResponse> => {
    const config = {
      headers: {
        "Accept-Account": payload.accountId || "",
      },
    };
    const response: IResponse = await this.instance
      .delete(`${RENEWABLE_OPTIONS}/Items/${payload.buyOrderItemId}`, config)
      .catch(catchAxiosError);

    return response;
  };

  public getEnergyType = async (): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${RENEWABLE_OPTIONS}/enumEnergies`)
      .catch(catchAxiosError);

    return response;
  };

  public getStandards = async (): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${RENEWABLE_OPTIONS}/enumSustainabilityStandards`)
      .catch(catchAxiosError);

    return response;
  };

  public getEWallet = async (): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${RENEWABLE_OPTIONS}/eWallet`)
      .catch(catchAxiosError);

    return response;
  };

  public submitBuyRECs = async (payload: BuyRecDTO): Promise<IResponse> => {
    const config = {
      headers: {
        "Accept-Account": payload.accountId || "",
      },
    };
    const response: IResponse = await this.instance
      .post(`${RENEWABLE_OPTIONS}/buy`, payload, config)
      .catch(catchAxiosError);

    return response;
  };

  public getRenewableOptionsTotal = async (
    data: IRenewableOptionsTotal
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${RENEWABLE_OPTIONS}/${data.Year}/total`)
      .catch(catchAxiosError);

    return response;
  };

  public getRenewableOptionDetails = async (params: {
    orderId: string;
  }): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${RENEWABLE_OPTIONS}/${params.orderId}/recDetail`)
      .catch(catchAxiosError);

    return response;
  };

  public downloadRenewableOptionDetails = async (): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${RENEWABLE_OPTIONS}/exports`)
      .catch(catchAxiosError);

    return response;
  };
  public updateBuyOrderItemById = async (payload: {
    accountId: string;
    quantity: number;
    buyOrderItemId: string;
  }): Promise<IResponse> => {
    const config = {
      headers: {
        "Accept-Account": payload.accountId || "",
      },
    };
    const response: IResponse = await this.instance
      .put(
        `${RENEWABLE_OPTIONS}/buyOrderItem/${payload.buyOrderItemId}`,
        { Quantity: payload.quantity },
        config
      )
      .catch(catchAxiosError);

    return response;
  };

  public deleteBuyOrderItemsByOwnerId = async (
    accountId: string
  ): Promise<IResponse> => {
    const config = {
      headers: {
        "Accept-Account": accountId || "",
      },
    };
    const response: IResponse = await this.instance
      .delete(`${RENEWABLE_OPTIONS}/Owners/BuyOrderItems`, config)
      .catch(catchAxiosError);

    return response;
  };
}
